import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import DashboardPage from './pages/DashboardPage';
import DataTablePage from './pages/DataTablePage';
import UsersPage from './pages/UsersPage';
import { Auth0Provider } from "@auth0/auth0-react";

import createExampleWrapper from 'cube-example-wrapper'

const exampleDescription = {
  title: "INFINYX Dashboard",
  text: `
  `
}
// createExampleWrapper(exampleDescription)

ReactDOM.render(
      <Auth0Provider
  domain="dev-ytagi0oy.us.auth0.com"
  clientId="ynoxacP51X8VoIYjg4TdMXJ8gGSNYURw"
  redirectUri={window.location.origin}
>
<React.StrictMode>
    <Router>
      <App>
        <Switch>
          <Redirect exact from="/" to="/dashboard" />
          <Route key="index" exact path="/dashboard" component={DashboardPage} />
          <Route key="table" path="/orders" component={DataTablePage} />
          <Route key="table" path="/user/:id" component={UsersPage} />
          <Redirect to="/dashboard" />
        </Switch>
      </App>
    </Router>
    </React.StrictMode>
    </Auth0Provider>,
  document.getElementById('root')
);
