import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import KPIChart from '../components/KPIChart';
import GroupKPIChart from '../components/GroupKPIChart';
import BarChart from '../components/BarChart.js';
import DoughnutChart from '../components/DoughnutChart.js';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const cards2 = [
  {
    title: 'TRANSACTIONS',
    query: { measures: ['Transactions.count'],
    timeDimensions: [
      {
        dimension: 'Transactions.createdAt',
        dateRange: 'Yesterday',
      },
    ],
    filters: [
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      }
    ] },
    duration: 1.25,
  },
  {
    title: 'NEW USERS',
    query: { measures: ['Customers.count'],
    timeDimensions: [
      {
        dimension: 'Customers.createdAt',
        dateRange: 'Yesterday',
      },
    ],
    dimensions: ['Customers.status'], },
    difference: 'Customers',
    duration: 1.5,
  },
  {
    title: 'NEW USERS(ACTIVE)',
    query: { measures: ['Customers.count'],
    timeDimensions: [
      {
        dimension: 'Customers.createdAt',
        dateRange: 'Yesterday',
      },
    ],
    dimensions: ['Customers.status'], 
    filters: [
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      }
    ]},
    difference: 'Customers',
    duration: 1.5,
  },
  { //  $data['gmv'] = Transaction::whereIn('type', [5, 6, 9])->wheredate('created_at', '>=', $from)->wheredate('created_at', '<=', $to)->where('status', '1')->sum('total_amount');
    title: 'GMV (THB)',
    query: { measures: ['Transactions.totalAmount'],
    filters: [
      {
        dimension: 'Transactions.type',
        operator: 'equals',
        values: ['5', '6', '9'],
      },
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      }
    ], 
    timeDimensions: [
      {
        dimension: 'Transactions.createdAt',
        dateRange: 'Yesterday',
      },
    ],
    dimensions: ['Transactions.status']},
    difference: 'Transactions',
    duration: 1.75,
  },
  {
    title: 'Points Collected (THB)',
    query: { measures: ['Transactions.totalCollected'],
    filters: [
      {
        dimension: 'Transactions.type',
        operator: 'equals',
        values: ['5', '6', '9'],
      },
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      }
    ],
    timeDimensions: [
      {
        dimension: 'Transactions.createdAt',
        dateRange: 'Yesterday',
      },
    ],
    dimensions: ['Transactions.status']  },
    difference: 'Customers',
    duration: 2.25,
  },
  { /*
    $pointsTransactions = Transaction::whereIn('type', [5, 6, 9])->wheredate('created_at', '>=', $from)->wheredate('created_at', '<=', $to)->where('status', '1')->get();
            foreach ($pointsTransactions as $pointsTransaction) {
                //$kickback = ($pointsTransaction->total_amount * $pointsTransaction['customer_rate'])/100;
                $data['total_points_collected'] += $pointsTransaction->collected;
            }
  */
    title: 'Points Redeemed (THB)',
    query: { measures: ['Transactions.totalRedeemedPoint'],
    filters: [
      {
        dimension: 'Transactions.type',
        operator: 'equals',
        values: ['5', '6', '9'],
      },
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      }
    ],
    timeDimensions: [
      {
        dimension: 'Transactions.createdAt',
        dateRange: 'Yesterday',
      },
    ],
    dimensions: ['Transactions.status'] },
    difference: 'Transactions',
    duration: 2.50,
  },
  { 
    title: 'Convert In (THB)',
    query: { measures: ['Transactions.totalAmount'],
    filters: [
      {
        dimension: 'Transactions.type',
        operator: 'equals',
        values: ['10', '13', '36'],
      },
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      }
    ],
    timeDimensions: [
      {
        dimension: 'Transactions.createdAt',
        dateRange: 'Yesterday',
      },
    ] },
    duration: 2.50,
  },
  { 
    title: 'Convert Out (THB)',
    query: { measures: ['Transactions.totalAmount'],
    filters: [
      {
        dimension: 'Transactions.type',
        operator: 'equals',
        values: ['14'],
      },
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      }
    ],
    timeDimensions: [
      {
        dimension: 'Transactions.createdAt',
        dateRange: 'Yesterday',
      },
    ],
    dimensions: ['Transactions.status'] },
    difference: 'Transactions',
    duration: 2.50,
  },
  // {
  //   title: 'TOTAL AUM(>= 20k THB/user) (THB)',
  //   query: { measures: ['Customers.totalAum'],
  //   filters: [
  //     {
  //       dimension: 'Customers.points',
  //       operator: 'gte',
  //       values: ['20000'],
  //     },
  //   ],
  //   timeDimensions: [
  //     {
  //       dimension: 'Transactions.createdAt',
  //       granularity: 'day',
  //       dateRange: 'Yesterday',
  //     },
  //   ],
  //   dimensions: ['Customers.status'] },
  //   difference: 'Customers',
  //   duration: 2.75,
  // },
  // {
  //   title: 'CLTV (2 Years) (THB) (Need data of How to calculate revenue) (Pending)',
  //   query: { measures: ['Transactions.cltv'],
  //   filters: [
  //     {
  //       dimension: 'Transactions.type',
  //       operator: 'equals',
  //       values: ['5', '6', '9'],
  //     },
  //     {
  //       dimension: 'Transactions.status',
  //       operator: 'equals',
  //       values: ['1'],
  //     },
  //     {
  //       dimension: 'Customers.status',
  //       operator: 'equals',
  //       values: ['1'],
  //     }
  //   ],
  //   timeDimensions: [
  //     {
  //       dimension: 'Transactions.createdAt',
  //       granularity: 'day',
  //       dateRange: 'Yesterday',
  //     },
  //   ],
  //   dimensions: ['Transactions.status']},
  //   difference: 'Customers',
  //   duration: 3.00,
  // },
  // {
  //   title: 'Churn rate(Not active > 1 Month) (Pending)',
  //   query: { measures: ['Customers.totalAum'],
  //   filters: [
  //     {
  //       dimension: 'Customers.points',
  //       operator: 'gte',
  //       values: ['20000'],
  //     },
  //   ],
  //   timeDimensions: [
  //     {
  //       dimension: 'Transactions.createdAt',
  //       granularity: 'day',
  //       dateRange: 'Yesterday',
  //     },
  //   ],
  //   dimensions: ['Transactions.status'] },
  //   difference: 'Customers',
  //   duration: 3.25,
  // },
  // {
  //   title: 'CAC (Pending Need data of total cost of marketing)',
  //   query: { measures: ['Customers.count'] },
  //   difference: 'Customers',
  //   duration: 1.5,
  // },
  // {
  //   title: 'CPG (Pending)',
  //   query: { measures: ['Customers.count'] },
  //   difference: 'Customers',
  //   duration: 1.5,
  // },
];

const cards1 = [
  {
    title: 'TOTAL TRANSACTIONS',
    query: { measures: ['Transactions.count'],
    filters: [
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      }
    ] },
    difference: 'Transactions',
    duration: 1.25,
  },
  {
    title: 'TOTAL USERS',
    query: { measures: ['Customers.count'] },
    difference: 'Customers',
    duration: 1.5,
  },
  { //  $data['gmv'] = Transaction::whereIn('type', [5, 6, 9])->wheredate('created_at', '>=', $from)->wheredate('created_at', '<=', $to)->where('status', '1')->sum('total_amount');
    title: 'TOTAL GMV (THB)',
    query: { measures: ['Transactions.totalAmount'],
    filters: [
      {
        dimension: 'Transactions.type',
        operator: 'equals',
        values: ['5', '6', '9'],
      },
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      }
    ]},
    difference: 'Transactions',
    duration: 1.75,
  },
  {
    title: 'TOTAL Points Collected (THB)',
    query: { measures: ['Transactions.totalCollected'],
    filters: [
      {
        dimension: 'Transactions.type',
        operator: 'equals',
        values: ['5', '6', '9'],
      },
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      }
    ]},
    difference: 'Customers',
    duration: 2.25,
  },
  { 
    title: 'TOTAL Points Redeemed (THB)',
    query: { measures: ['Transactions.totalCollected'],
    filters: [
      {
        dimension: 'Transactions.type',
        operator: 'equals',
        values: ['5', '6', '9'],
      },
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      }
    ]},
    difference: 'Transactions',
    duration: 2.50,
  },
  // {
  //   title: 'TOTAL AUM(>= 20k THB/user) (THB)',
  //   query: { measures: ['Customers.totalAum'],
  //   filters: [
  //     {
  //       dimension: 'Customers.points',
  //       operator: 'gte',
  //       values: ['20000'],
  //     },
  //     {
  //       dimension: 'Customers.status',
  //       operator: 'equals',
  //       values: ['1'],
  //     },
  //   ]},
  //   duration: 2.75,
  // },
   {
    title: 'TOTAL CUSTOMERS GET 3%(>= 20k THB/user)',
    query: { measures: ['Customers.count'],
    filters: [
      {
        dimension: 'Customers.availablePoints',
        operator: 'gte',
        values: ['20000'],
      },
      {
        dimension: 'Customers.status',
        operator: 'equals',
        values: ['1'],
      },
    ]},
    duration: 2.75,
  },
  {
    title: 'TOTAL AUM GET 3%(>= 20k THB/user) (THB)',
    query: { measures: ['Customers.totalAum2'],
    filters: [
      {
        dimension: 'Customers.availablePoints',
        operator: 'gte',
        values: ['20000'],
      },
      {
        dimension: 'Customers.status',
        operator: 'equals',
        values: ['1'],
      },
    ]},
    duration: 2.75,
  },
  {
    title: 'TOTAL AUM(THB)',
    query: { measures: ['Customers.totalAum'], 
    filters: [
      {
        dimension: 'Customers.status',
        operator: 'equals',
        values: ['1'],
      },
    ]},
    duration: 2.75,
  },
  {
    title: 'TOTAL AUM(THB) without Bonus point',
    query: { measures: ['Customers.totalAum2'], 
    filters: [
      {
        dimension: 'Customers.status',
        operator: 'equals',
        values: ['1'],
      },
    ]},
    duration: 2.75,
  }
  ,
  { 
    title: 'TOTAL Convert In (THB)',
    query: { measures: ['Transactions.totalAmount'],
    filters: [
      {
        dimension: 'Transactions.type',
        operator: 'equals',
        values: ['10', '13', '36'],
      },
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      }
    ]},
    difference: 'Transactions',
    duration: 2.50,
  },
  { 
    title: 'TOTAL Convert Out (THB)',
    query: { measures: ['Transactions.totalAmount'],
    filters: [
      {
        dimension: 'Transactions.type',
        operator: 'equals',
        values: ['14'],
      },
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      }
    ]},
    difference: 'Transactions',
    duration: 2.50,
  },
  { 
    title: 'Total Citbank Convert In(Volume)(FYX/THB)',
    query: { measures: ['Transactions.totalAmount'],
    filters: [
      {
        dimension: 'Transactions.type',
        operator: 'equals',
        values: ['10', '13', '36'],
      },
      {
        dimension: 'Transactions.pointIssuerId',
        operator: 'equals',
        values: ['15'],
      },
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      },
      {
        dimension: 'Transactions.createdAt',
        operator: 'gte',
        values: ['2022-07-15'],
      },
      {
        dimension: 'Transactions.createdAt',
        operator: 'lt',
        values: ['2022-08-01'],
      }
    ]},
    difference: 'Transactions',
    duration: 2.50,
  },
  { 
    title: 'Total Citbank Convert In(Volume)(Citi Point)',
    query: { measures: ['Transactions.totalCitiPoint'],
    filters: [
      {
        dimension: 'Transactions.type',
        operator: 'equals',
        values: ['10', '13', '36'],
      },
      {
        dimension: 'Transactions.pointIssuerId',
        operator: 'equals',
        values: ['15'],
      },
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      },
      {
        dimension: 'Transactions.createdAt',
        operator: 'gte',
        values: ['2022-07-15'],
      },
      {
        dimension: 'Transactions.createdAt',
        operator: 'lt',
        values: ['2022-08-01'],
      }
    ]},
    difference: 'Transactions',
    duration: 2.50,
  },
  { 
    title: 'Total Citbank FYX Bonus Distributed(FYX/THB)',
    query: { measures: ['Transactions.totalAmount'],
    filters: [
      {
        dimension: 'Transactions.pointIssuerId',
        operator: 'equals',
        values: ['39'],
      },
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      },
      {
        dimension: 'Transactions.createdAt',
        operator: 'gte',
        values: ['2022-07-15'],
      },
      {
        dimension: 'Transactions.createdAt',
        operator: 'lt',
        values: ['2022-08-02'],
      }
    ]},
    difference: 'Transactions',
    duration: 2.50,
  },{ 
    title: 'Total SCB Convert In(Volume)(FYX/THB)',
    query: { measures: ['Transactions.totalAmount'],
    filters: [
      {
        dimension: 'Transactions.type',
        operator: 'equals',
        values: ['10', '13', '36'],
      },
      {
        dimension: 'Transactions.pointIssuerId',
        operator: 'equals',
        values: ['12'],
      },
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      },
      {
        dimension: 'Transactions.createdAt',
        operator: 'gte',
        values: ['2022-08-16'],
      },
      {
        dimension: 'Transactions.createdAt',
        operator: 'lt',
        values: ['2022-09-02'],
      }
    ]},
    difference: 'Transactions',
    duration: 2.50,
  },
  { 
    title: 'Total SCB Convert In(Volume)(Citi Point)',
    query: { measures: ['Transactions.totalCitiPoint'],
    filters: [
      {
        dimension: 'Transactions.type',
        operator: 'equals',
        values: ['10', '13', '36'],
      },
      {
        dimension: 'Transactions.pointIssuerId',
        operator: 'equals',
        values: ['12'],
      },
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      },
      {
        dimension: 'Transactions.createdAt',
        operator: 'gte',
        values: ['2022-08-16'],
      },
      {
        dimension: 'Transactions.createdAt',
        operator: 'lt',
        values: ['2022-09-02'],
      }
    ]},
    difference: 'Transactions',
    duration: 2.50,
  },
  { 
    title: 'Total SCB FYX Bonus Distributed(FYX/THB)',
    query: { measures: ['Transactions.totalAmount'],
    filters: [
      {
        dimension: 'Transactions.pointIssuerId',
        operator: 'equals',
        values: ['40'],
      },
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      },
      {
        dimension: 'Transactions.createdAt',
        operator: 'gte',
        values: ['2022-08-16'],
      },
      {
        dimension: 'Transactions.createdAt',
        operator: 'lt',
        values: ['2022-09-03'],
      }
    ]},
    difference: 'Transactions',
    duration: 2.50,
  },
  { 
    title: 'Active User(30 Days)',
    query: { measures: ['Customers.count'],
    timeDimensions: [
      {
        dimension: 'Transactions.createdAt',
        dateRange: 'Last 30 days',
      },
    ],
    filters: [
      {
        dimension: 'Transactions.status',
        operator: 'equals',
        values: ['1'],
      }
    ]},
    duration: 1.5,
  }


    //SELECT id, customer_id, total_amount, to_name, employee_id, created_at FROM transactions where created_at > '${createdat}' and botstartat < '${botstartat}' and status = 1 and (type = 10 or type = 13 or type = 36)

  // {
  //   title: 'CLTV (2 Years) (THB) (Need data of How to calculate revenue) (Pending)',
  //   query: { measures: ['Transactions.cltv'],
  //   filters: [
  //     {
  //       dimension: 'Transactions.type',
  //       operator: 'equals',
  //       values: ['5', '6', '9'],
  //     },
  //     {
  //       dimension: 'Transactions.status',
  //       operator: 'equals',
  //       values: ['1'],
  //     },
  //     {
  //       dimension: 'Customers.status',
  //       operator: 'equals',
  //       values: ['1'],
  //     }
  //   ]},
  //   difference: 'Customers',
  //   duration: 3.00,
  // },
  // {
  //   title: 'Churn rate(Not active > 1 Month) (Pending)',
  //   query: { measures: ['Customers.totalAum'],
  //   filters: [
  //     {
  //       dimension: 'Customers.points',
  //       operator: 'gte',
  //       values: ['20000'],
  //     },
  //   ]},
  //   difference: 'Customers',
  //   duration: 3.25,
  // },
  // {
  //   title: 'CAC (Pending Need data of total cost of marketing)',
  //   query: { measures: ['Customers.count'] },
  //   difference: 'Customers',
  //   duration: 1.5,
  // },
  // {
  //   title: 'CPG (Pending)',
  //   query: { measures: ['Customers.count'] },
  //   difference: 'Customers',
  //   duration: 1.5,
  // },
];

// const barChartQuerybak = {
//   measures: ['Customers.count'],
//   timeDimensions: [
//     {
//       dimension: 'Customers.createdAt',
//       granularity: 'day',
//       dateRange: 'Yesterday',
//     },
//   ],
//   dimensions: ['Customers.status'],
//   filters: [
//     {
//       dimension: 'Customers.status',
//       operator: 'equals',
//       values: ['1'],
//     },
//   ],
// };

const Dashboard = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {cards1.map((item, index) => {
          return (
            <Grid key={item.title + index} item lg={2} sm={4} xl={2} xs={6}>
              <KPIChart {...item} />
            </Grid>
          );
        })}
        <Grid container spacing={4}>
        {cards2.map((item, index) => {
          return (
            <Grid key={item.title + index} item lg={3} sm={6} xl={3} xs={12}>
              <GroupKPIChart {...item} />
            </Grid>
          );
        })}
          {/* <BarChart query={barChartQuery} /> */}
        </Grid>
        {/* <Grid item lg={4} md={6} xl={3} xs={12}>
          <DoughnutChart query={doughnutChartQuery} />
        </Grid> */}
      </Grid>
    </div>
  );
};

export default Dashboard;
