import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { CubeProvider } from '@cubejs-client/react';
import theme from './theme';
import 'typeface-roboto';
import { Main } from './layouts';
import { initCubejsApi } from './init-cubejs-api';
import config from './auth_config';
import { useAuth0 } from "@auth0/auth0-react";
import cubejs from '@cubejs-client/core';
import 'rsuite/dist/rsuite.min.css';

const cubejsApi = cubejs( 
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTk0OTUyMzJ9.HF0zM_VgFyLh7XUOZjpoeQueYwvrzTKHdxrwfaT6_4k', 
  { apiUrl: 'https://cube.infinyxapp.com/cubejs-api/v1' } 
); 

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '150vh'
  },
}));

const AppLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Main>
        <div className={classes.root}>
          <div>{children}</div>
        </div>
      </Main>
    </ThemeProvider>
  );
};

const App = ({ children }) => (
  <CubeProvider cubejsApi={cubejsApi}>
    <AppLayout>{children}</AppLayout>
  </CubeProvider>
);



export default App;
