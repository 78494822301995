import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Divider, Grid, Typography, LinearProgress } from '@material-ui/core';
import BarChartHeader from './BarChartHeader';
import ChartRenderer from './ChartRenderer';
import { useCubeQuery } from '@cubejs-client/react';
import CountUp from 'react-countup';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    position: 'relative',
    padding: '19px 0',
  },
}));

const GroupKPIChart = (props) => {
  const {title, duration, className, query, dates, ...rest } = props;
  // console.log(query);
  const classes = useStyles();

  const [dateRange, setDateRange] = React.useState(dates ? dates : 'Yesterday');
  let queryWithDate = {
    ...query,
    timeDimensions: [
      {
        dimension: `${query.timeDimensions[0].dimension}`,
        dateRange: dateRange
      },
    ],
    // timezone: 'Asia/Bangkok'
  };

  // console.log(queryWithDate);
  // console.log(`TESTED`);

  const { resultSet, error, isLoading } = useCubeQuery(queryWithDate);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color="secondary" />
      </div>
    );
  }
  if (error) {
    return <pre>{(error).toString()}</pre>;
  }
  if (!resultSet) {
    return null;
  }
  if (resultSet) {

  // console.log('resultSet: ', resultSet);

  let postfix = null;
  let prefix = null;
  //const measureKey = resultSet.seriesNames()[0].key;
  //const annotations = resultSet.tableColumns().find((tableColumn) => tableColumn.key === measureKey);
//   const format = annotations.format || (annotations.meta && annotations.meta.format);
//   if (format === 'percent') {
//     postfix = '%';
//   } else if (format === 'currency') {
//     prefix = '$';
//   }

  let value = null;
  let fullValue = resultSet.seriesNames().map((s) => resultSet.totalRow()[s.key])[0];

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <BarChartHeader dates={dates} dateRange={dateRange} setDateRange={setDateRange} />
      <Divider />
      <CardContent>
        {/* <div className={classes.chartContainer}>
          <ChartRenderer vizState={{ query: queryWithDate, chartType: 'bar' }} />
        </div> */}
        <Grid container justify="space-between">
          <Grid item>
            <Typography className={classes.title} color="textSecondary" gutterBottom variant="body2">
              {title}
            </Typography>
            <Typography variant="h3">
              <CountUp end={fullValue} duration={duration} separator="," decimals={0} />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
    }

};

GroupKPIChart.propTypes = {
  className: PropTypes.string,
};

export default GroupKPIChart;
