import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { CardHeader, Button } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DateRangePicker from 'rsuite/DateRangePicker';

const useStyles = makeStyles(() => ({
  headerButton: {
    letterSpacing: '0.4px',
  },
}));

const BarChartHeader = (props) => {
  const { setDateRange, dateRange, dates } = props;
  const defaultDates = ['Yesterday', 'Today' ,'This week', 'This month', 'Last 7 days', 'Last 14 days', 'Last month', 'Custom'];
  // const [value, setValue] = React.useState([new Date('2017-02-01'), new Date('2017-05-20')]);
  const [showResults, setShowResults] = React.useState(false)
  const [tempText, setTempText] = React.useState("");
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    event.persist();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (date) => {
    // e.persist();
    if(date !== 'Custom') {
      setTempText("");
      setDateRange(date);
      setAnchorEl(null);
      setShowResults(false);
    } else {
      setTempText("Custom");
      setAnchorEl(null);
      setShowResults(true);
      // showDatePicker
    }
  };

  const handleChangeDateRange = (dDate) => {
      // e.persist();
      let date = dDate;
      // console.log(date);
      let newdate = [];
      newdate[0] = date[0].toISOString().slice(0, 10); 
      newdate[1] = date[1].toISOString().slice(0, 10); 
      // console.log(newdate);
      // console.log(date.toString());
      //let test = ["2022-01-01", "2022-02-02"]
      //console.log(test);
      setDateRange(newdate);
      setAnchorEl(null);
  };

//   const handleCleanDateRange = (e) => {
//     e.persist();
// };

  return (
    <CardHeader
      action={
        <div>
          <Button
            className={classes.headerButton}
            size="small"
            variant="text"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            { 
              tempText === "Custom" ? tempText : dateRange  
            }
            <ArrowDropDownIcon />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => handleClose(dateRange)}
          >
            {dates
              ? dates.map((date) => (
                  <MenuItem key={date} onClick={() => handleClose(date)}>
                    {date}
                  </MenuItem>
                ))
              : defaultDates.map((date) => (
                  <MenuItem key={date} onClick={() => handleClose(date)}>
                    {date}
                  </MenuItem>
                ))}
          </Menu>
          { showResults ? <DateRangePicker format="yyyy-MM-dd" placement="auto" onChange={(value) => handleChangeDateRange(value)} /> : null }
          
        </div>
      }
      title=""
    />
  );
};

BarChartHeader.propTypes = {
  className: PropTypes.string,
};

export default BarChartHeader;
